<template>
  <p
    ref="textContainer"
    class="show-more-wrapper"
    :class="{
      'tw-line-clamp-[--line-clamp]': !isOpen,
    }"
  >
    <de-view-more-button
      v-if="!isOpen && isOverflowing"
      type="link"
      :label="$t('common.buttons.readMore')"
      class="show-more"
      @click="onShowMoreClick"
    />

    <slot />

    <button
      v-if="isOverflowing"
      class="tw-text-primary-300 tw-font-semibold tw-text-300 tw-leading-400 tw-underline tw-ml-1"
      @click="onShowMoreClick"
    >
      {{ $t('common.buttons.showLess') }}
    </button>
  </p>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from 'vue';
import DeViewMoreButton from '~/shared/components/DeViewMoreButton.vue';

const textContainer = ref<HTMLElement | null>(null);
const isOverflowing = ref(false);
const isOpen = ref(false);

function onShowMoreClick() {
  isOpen.value = !isOpen.value;
}

function checkOverflow() {
  if (!textContainer.value) return;

  isOverflowing.value = textContainer.value.scrollHeight > textContainer.value.clientHeight;
}

onMounted(() => {
  checkOverflow();
  window.addEventListener('resize', checkOverflow);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', checkOverflow);
});
</script>
